<template>
  <div>
    <Card>
      <template #title>Usuários</template>
      <template #content>
        <div class="flex mb-3">
          <form class="flex-grow-1" @submit.prevent="onFilter()">
            <div class="p-inputgroup">
              <InputText
                :value="filters.pesquisa.value"
                autofocus
                type="search"
                minlength="3"
                required="required"
                :allowEmpty="false"
                :useGrouping="false"
                placeholder="Nome, CPF ou email."
                @input="sanitizeFiltros" />
              <Button class="p-button" @click="onFilter()">
                <span v-if="!loading" class="pi pi-search"></span>
                <span v-if="loading" class="pi pi-spin pi-spinner"></span>
                <span class="ml-2">Buscar</span>
              </Button>
            </div>
          </form>
          <Button
            class="flex-none ml-2 pr-3 p-button-secondary"
            icon="pi pi-filter-slash"
            label="Limpar"
            aria:haspopup="true"
            aria-controls="overlay_panel"
            @click="clearFilters()" />

          <Button
            class="ml-3"
            label="Novo Usuário"
            icon="pi pi-user"
            @click="showNovoUsuario(null)" />
        </div>
        <DataTable
          ref="dt"
          class="p-datatable-sm"
          :value="usuarios"
          :paginator="true"
          :rows="15"
          :totalRecords="totalRecords"
          :lazy="true"
          :rowsPerPageOptions="[10, 15, 20]"
          :loading="loading"
          :sortField="sort"
          :sortOrder="1"
          :filters.sync="filters"
          filterDisplay="menu"
          :globalFilterFields="['nome', 'email', 'username']"
          dataKey="id"
          stripedRows
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          responsiveLayout="scroll"
          @page="onPage($event)"
          @sort="onSort($event)">
          <template #empty> Nenhuma usuário encontrado. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <Column field="username" header="CPF" />
          <Column field="nome" header="Nome" sortable />
          <Column field="email" header="Email" />
          <Column header="Ações">
            <template #body="slotProps">
              <div class="flex justify-content-evenly">
                <div class="relative m-1">
                  <Button
                    v-tooltip.left="'Editar Usuário'"
                    class="static p-button-rounded p-button-primary"
                    @click="showNovoUsuario(slotProps)">
                    <i class="pi pi-user-edit py-1" aria-hidden="true"></i>
                    <Badge
                      v-show="slotProps.data.entidades.length > 0"
                      :value="slotProps.data.entidades.length"
                      severity="success"
                      class="absolute top-0 right-0 -mr-1 -mt-1"></Badge>
                  </Button>
                </div>

                <div class="relative m-1">
                  <Button
                    v-tooltip.left="'Gestão Entidade'"
                    class="static p-button-rounded p-button-primary"
                    @click="toggleEntidades(slotProps)">
                    <i class="pi pi-building py-1" aria-hidden="true"></i>
                    <Badge
                      v-show="slotProps.data.entidades.length > 0"
                      :value="slotProps.data.entidades.length"
                      severity="success"
                      class="absolute top-0 right-0 -mr-1 -mt-1"></Badge>
                  </Button>
                </div>

                <div class="relative m-1">
                  <Button
                    v-tooltip.left="'Consignatarias'"
                    class="static p-button-rounded p-button-warning"
                    @click="toggleConsignatarias(slotProps)">
                    <i class="pi pi-wallet py-1" aria-hidden="true"></i>
                    <Badge
                      v-show="slotProps.data.consignatarias.length > 0"
                      :value="slotProps.data.consignatarias.length"
                      severity="info"
                      class="absolute top-0 right-0 -mr-1 -mt-1"></Badge>
                  </Button>
                </div>

                <div class="relative m-1">
                  <Button
                    v-tooltip.left="'Permissões'"
                    class="static p-button-rounded p-button-danger"
                    @click="togglePermissoes(slotProps)">
                    <i class="pi pi-lock-open py-1" aria-hidden="true"></i>
                  </Button>
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>

    <ConsignatariasDialog
      :visible.sync="showConsignatariaDialog"
      :usuario="usuarioSelecionado"
      @onUpdate="onUpdateConsignataria" />

    <PermissoesDialog
      :visible.sync="showPermissoesDialog"
      :usuario="usuarioSelecionado" />

    <NovoUsuarioDialog
      :visible.sync="showNovoUsuarioConsignataria"
      :usuario="usuarioSelecionado"
      @onUpdate="onUpdateUsuario" />

    <Dialog
      :visible.sync="entidadesDialog"
      :closeOnEscape="true"
      header="Gestão Entidade"
      :closable="true"
      :modal="true"
      class="p-fluid"
      @hide="hideEntidade">
      <div class="field col-12 md:col-12">
        <PickList
          v-model="entidades"
          dataKey="id"
          :stripedRows="false"
          :selection.sync="entidadesSelecionadas"
          listStyle="height:342px; width: 350px;"
          @move-to-target="moveToTarget($event)"
          @move-to-source="moveToSource($event)">
          <template #sourceheader> Disponível </template>
          <template #targetheader> Selecionado </template>
          <template #item="entidade">
            <div>
              <span>{{ entidade.item.nome }}</span>
            </div>
          </template>
        </PickList>
      </div>
      <form class="mr-5 ml-5">
        <div v-if="loading" class="flex justify-content-center flex-wrap m-4">
          <ProgressSpinner style="width: 50px; height: 50px" />
        </div>
      </form>

      <template #footer>
        <Button
          label="Fechar"
          icon="pi pi-times"
          class="p-button-text"
          @click="entidadesDialog = false" />

        <Button label="Salvar" icon="pi pi-check" @click="salvar()" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import ConsignatariasDialog from './ConsignatariasDialog.vue'
import PermissoesDialog from './PermissoesDialog.vue'
import NovoUsuarioDialog from './NovoUsuarioDialog.vue'
import UsuarioService from '@/service/UsuarioService.js'
import EntidadeService from '@/service/EntidadeService'

export default {
  components: {
    ConsignatariasDialog,
    PermissoesDialog,
    NovoUsuarioDialog,
  },

  data() {
    return {
      usuarios: null,
      usuarioSelecionado: null,
      loading: false,
      totalRecords: 0,
      lazyParams: {},
      entidades: [],
      entidadesSelecionadas: [],
      entidadesDialog: false,
      entidadesLoading: false,
      showConsignatariaDialog: false,
      showPermissoesDialog: false,
      showNovoUsuarioConsignataria: false,
      novoUsuarioDialog: false,
      filters: {
        pesquisa: { value: null, matchMode: 'exactly' },
      },
      sort: 'nome',
      searching: false,
    }
  },

  mounted() {
    this.lazyParams = {
      first: 0,
      size: this.$refs.dt.rows,
      sort: this.$refs.dt.sortField,
      sortOrder: 1,
      filters: this.filters,
    }
    this.carregarDados()
  },

  created() {
    this.usuarioService = new UsuarioService(this.$http)
    this.entidadeService = new EntidadeService(this.$http)
  },

  methods: {
    sanitizeFiltros(value) {
      if (this.searching) {
        let sanitizedValue = value.trim()
        if (!value.includes('@')) {
          sanitizedValue = sanitizedValue.replace(/[^\w\s]|[-]/g, '')
        }
        this.filters.pesquisa.value = sanitizedValue
      }
    },

    onFilter() {
      this.lazyParams.filters = this.filters
      this.searching = true
      this.carregarDados()
    },

    onPage(event) {
      this.lazyParams.page = event.page
      this.lazyParams.size = event.rows
      this.lazyParams.sort = event.sortField
      this.carregarDados()
    },

    onSort(event) {
      this.lazyParams.size = event.rows
      this.lazyParams.sort = event.sortField
      this.carregarDados()
    },

    showNovoUsuario(slotProps) {
      if (slotProps != null) {
        this.usuarioSelecionado = slotProps.data
      } else {
        this.usuarioSelecionado = {}
      }
      this.showNovoUsuarioConsignataria = true
    },

    carregarDados() {
      this.loading = true
      this.usuarioService
        .buscarUsuarioAll(this.lazyParams)
        .then((res) => {
          console.log('RES', res)
          this.usuarios = res.content
          this.totalRecords = res.totalElements
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },

    onUpdateConsignataria(event) {
      let index = this.usuarios.findIndex((el) => el.id === event.id)
      this.$set(this.usuarios, index, event)
    },

    onUpdateUsuario(event) {
      let index = this.usuarios.findIndex((el) => el.id === event.id)
      this.$set(this.usuarios, index, event)
    },

    clearFilters() {
      this.filters.pesquisa = { value: null, matchMode: 'exactly' }
      this.usuarios = null
    },

    toggleConsignatarias(slotProps) {
      this.usuarioSelecionado = slotProps.data
      this.consignatariasSelecionadas = slotProps.data.consignatarias
      this.showConsignatariaDialog = true
    },

    togglePermissoes(slotProps) {
      this.usuarioSelecionado = slotProps.data
      this.showPermissoesDialog = true
    },

    toggleEntidades(slotProps) {
      this.entidadesSelecionadas = []
      this.entidades = []
      this.usuarioSelecionado = slotProps.data
      if (this.entidades.length == 0 && !this.entidadesLoading) {
        this.entidadesLoading = true
        this.entidadeService
          .getListaEntidades()
          .then((res) => {
            const idx = slotProps.data.entidades.map((a) => a.id)
            const ent = res.filter((value) => !idx.includes(value.id))
            this.entidadesSelecionadas = JSON.parse(
              JSON.stringify(slotProps.data.entidades),
            )
            this.entidades = [ent, slotProps.data.entidades]
            this.sortAll()
          })
          .catch((err) => {
            this.$toast.add({
              severity: 'error',
              summary: err.response.data.message,
              life: 10000,
            })
          })
          .finally(() => {
            this.entidadesLoading = false
          })
      }
      this.entidadesDialog = true
    },

    hideEntidade() {
      this.entidadesSelecionadas = []
      this.entidades = []
    },

    moveToTarget() {
      this.sortAll()
    },

    moveToSource() {
      this.sortAll()
    },

    sortAll() {
      this.entidades[0].sort((a, b) => this.compare(a, b))
      this.entidades[1].sort((a, b) => this.compare(a, b))
    },

    compare(a, b) {
      if (a.nome > b.nome) {
        return 1
      }
      if (b.nome > a.nome) {
        return -1
      }
      return 0
    },

    salvar() {
      let usuario = JSON.parse(JSON.stringify(this.usuarioSelecionado))
      usuario.entidades = this.entidades[1]

      this.entidadesLoading = true
      this.usuarioService
        .saveUsuario(usuario)
        .then((res) => {
          this.entidadesDialog = false
          const index = this.usuarios.findIndex(
            (element) => element.id === usuario.id,
          )
          this.$set(this.usuarios, index, res.data)
          this.$toast.add({
            severity: 'success',
            summary: 'Usuário atualizado com sucesso.',
            life: 10000,
          })
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
        .finally(() => {
          this.entidadesLoading = false
        })
    },

    selectGrupo() {},
  },
}
</script>

<style scoped>
::v-deep .p-picklist-source-controls {
  display: none;
}

::v-deep .p-picklist-target-controls {
  display: none;
}

::v-deep .p-button .p-badge {
  min-width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  font-size: 1em;
}
</style>
